import { Address } from '@core/models/address.model';
import { Injectable } from "@angular/core";
import {ClearAuthData, RefreshUser, SetSession, SetNewToken, SetUser, UpdateUserAddress} from './auth.actions';
import { State, Selector, Action, StateContext, NgxsOnInit } from '@ngxs/store';
import {catchError, tap} from 'rxjs/operators';
import { User } from '@core/models/user.model';
import { SessionModelResponse } from '@core/models/session-model-response.model';
import {HttpClient} from '@angular/common/http';
import {API_LIST} from '@core/services/web-api/api-list';
import {throwError} from 'rxjs';

export class AuthStateModel {
  token: string | null = null;
  user: User | null;
  isLoadingUser = true;
  address: Address | null;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: new AuthStateModel(),
})
@Injectable()
export class AuthState implements NgxsOnInit {
  @Selector()
  static token(state: AuthStateModel): string | null {
    return state.token;
  }

  @Selector()
  static user(state: AuthStateModel): User | null {
    return state.user;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return Boolean(state.token);
  }

  @Selector()
  static isLoadingUser(state: AuthStateModel): boolean {
    return Boolean(state.isLoadingUser);
  }

  @Selector()
  static billingAddressExists(state: AuthStateModel): boolean {
    return Boolean(state.address.billing_created_at);
  }

  @Selector()
  static shippingAddressExists(state: AuthStateModel): boolean {
    return Boolean(state.address.shipping_created_at);
  }

  @Selector()
  static address(state: AuthStateModel): Address | null {
    return state.address;
  }

  @Selector()
  static state(state: AuthStateModel): AuthStateModel | null {
    return state;
  }

  constructor(private httpClient: HttpClient) { }

  ngxsOnInit(ctx: StateContext<AuthStateModel>) { }

  @Action(RefreshUser)
  refreshUser(ctx: StateContext<AuthStateModel>, action: RefreshUser) {
    const state = ctx.getState();

    if (!state.token) {
      ctx.patchState({
        user: null,
        isLoadingUser: false,
      });
      return;
    }

    return this.httpClient.get<SessionModelResponse>(API_LIST.currentSessionData()).pipe(
      tap((session: SessionModelResponse) => {
        ctx.patchState({
          token: session.id,
          user: session.user,
          isLoadingUser: false,
        });
      }),
      catchError(err => {
        ctx.patchState({
          user: null,
          token: null,
          isLoadingUser: false,
        });

        return throwError(err);
      })
    );
  }

  @Action(SetUser)
  setUser(ctx: StateContext<AuthStateModel>, action: SetUser) {
    ctx.patchState({
      user: action.user,
      isLoadingUser: false,
    });
  }

  @Action(SetNewToken)
  setNewToken(ctx: StateContext<AuthStateModel>, action: SetNewToken) {
    ctx.patchState({
      token: action.token,
      isLoadingUser: true,
      user: undefined,
      address: undefined,
    });
  }

  @Action(SetSession)
  setSession(ctx: StateContext<AuthStateModel>, action: SetSession) {
    ctx.patchState({
      token: action.session.id,
      user: action.session.user,
      isLoadingUser: false,
    });
  }

  @Action(ClearAuthData)
  clearAuthData(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({
      user: null,
      token: null,
      isLoadingUser: false,
    });
  }

  @Action(UpdateUserAddress)
  updateUserAddress(ctx: StateContext<AuthStateModel>) {
    const state = ctx.getState();

    if (!state.token) {
      ctx.patchState({
        address: null,
      });
      return;
    }

    this.httpClient.get<Address>(API_LIST.getAddress()).subscribe((data: Address) => {
      ctx.patchState({
        address: data,
      });
    });
  }
}
