import { Component, OnDestroy, OnInit, Input, ElementRef, TemplateRef, ViewChild, ViewContainerRef, inject, EventEmitter, Output } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';

import { Cart } from '@core/models/cart.model';
import { GonativeService } from '@core/services/gonative/gonative.service';
import { Select } from '@ngxs/store';
import { CartState } from '@store/cart/cart.state';
import { fromEvent, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MessagePopup, MessagePopupService } from '@core/services/message-popup/message-popup.service';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { InstallPromptComponent } from '@components/install-prompt/install-prompt.component';
import { NavigationStart, Router, RouterModule } from '@angular/router';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { SvgIconComponent } from '@components/svg-icon/svg-icon.component';
import { SettingsState } from '@store/settings/settings.state';
import { HeaderAppBannerComponent } from '@components/header-app-banner/header-app-banner.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [SvgIconComponent, InstallPromptComponent, HeaderAppBannerComponent, AsyncPipe, NgIf, NgFor, NgClass, RouterModule],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() showInstallPrompt = true;
  @Output() headerSizeShortChange = new EventEmitter<boolean | null>();

  @ViewChild('templateMobileMenu')
  templateRefMobileMenu!: TemplateRef<ElementRef>;


  public alive$: Subject<void> = new Subject();
  public timeout: number;
  public isSubHeaderVisible: boolean;
  public isMobileMenuVisible: boolean;
  public cart: Cart | null;
  public isNativeAppVisible = false;
  public isIos = false;
  public isAndroid = false;
  public isShort = false;
  public isActivePrompt = false;
  public appHomepageBannerText: string | null;
  public messages$: Observable<MessagePopup[]>;

  @Select(CartState.cart) cart$: Observable<Cart | null>;
  @Select(SettingsState.getAppBanner) appHomepageBanner$: Observable<string | null>;

  private overlayRef!: OverlayRef;
  private viewContainerRef = inject(ViewContainerRef);
  private destroy$ = new Subject();

  private overlay = inject(Overlay);


  constructor(
    private window: Window,
    private router: Router,
    private messagePopupService: MessagePopupService,
    private breakpointObserver: BreakpointObserver,
  ) {
    this.messages$ = this.messagePopupService.messages$;
    this.appHomepageBanner$.pipe(takeUntil(this.alive$)).subscribe((text: string | null) => {
      this.appHomepageBannerText = text;
    });
  }

  ngOnInit(): void {
    this.listenRouterEvents();
    this.isNativeAppVisible = GonativeService.isGonative();
    this.isIos = GonativeService.isIos();
    this.isAndroid = GonativeService.isAndroid();
    this.checkHeaderVisability();

    fromEvent(this.window, 'scroll').pipe(
      takeUntil(this.alive$)
    ).subscribe(() => {
      if (this.timeout) {
        this.window.cancelAnimationFrame(this.timeout);
      }

      this.timeout = this.window.requestAnimationFrame(() => {
        this.checkHeaderVisability();
      });

      this.isShort = window.scrollY > 150;

      this.headerSizeShortChange.emit(this.isShort);
    });

    this.breakpointObserver
      .observe('(min-width: 1200px)')
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        if (value.matches) {
          this.close();
        }
      });
  }

  ngOnDestroy(): void {
    this.alive$.next();
    this.alive$.complete();
  }

  checkHeaderVisability(): void {
    const headerPosition = window.pageYOffset;
    this.isSubHeaderVisible = headerPosition > 200;
  }

  handleMobileMenuOpen(): void {
    this.open();
  }

  handleMobileMenuClose(): void {
    this.isMobileMenuVisible = false;
    this.close();
  }

  stopPropagation(e: MouseEvent): void {
    e.stopPropagation();
  }

  goBack(event: Event): void {
    event.preventDefault();
    history.back();
  }



  open(): void {
    this.overlayRef = this.overlay.create(this.setOverlayConfig());
    const portal = new TemplatePortal(
      this.templateRefMobileMenu,
      this.viewContainerRef
    );
    this.overlayRef.attach(portal);
    this.overlayRef
      .backdropClick()
      .pipe(take(1))
      .subscribe({
        next: () => this.close(),
      });
    this.isMobileMenuVisible = true;
  }

  close(): void {
    this.isMobileMenuVisible = false;
    setTimeout(() => {
      this.overlayRef?.dispose();
    }, 300);
  }

  private listenRouterEvents(): void {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe({
      next: (event) => {
        if (event instanceof NavigationStart) {
          this.close();
        }
      },
    });
  }

  private setOverlayConfig() {
    let className = 'header-menu--backdrop';

    if (this.isActivePrompt && !this.isNativeAppVisible) {
      className = 'header-menu--backdrop-prompt';
    }

    if (this.appHomepageBannerText && this.isNativeAppVisible) {
      className = 'header-menu--backdrop-banner';
    }

    const config = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: className,
      panelClass: 'position-right',
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });

    return config;
  }

  handlePrompt(event) {
    this.isActivePrompt = event;
    if (!event) {
      this.overlayRef.backdropElement.style.top = '62px';
    }
  }

  navigateForMobileMenuItem(): void {
    this.overlayRef.detachments().pipe(take(1)).subscribe({
      next: () => {
        window.scrollTo(0, 0); // or whatever the code should be
      }
    });

    this.close();
  }

}
