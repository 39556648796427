<div class="header-placeholder" [class.header-placeholder--banner]="appHomepageBannerText && isNativeAppVisible && !isActivePrompt" [class.header-placeholder--prompt]="!isNativeAppVisible && isActivePrompt"></div>

<ng-container *ngFor="let message of messages$ | async">
  <div class="alert alert_{{message.type}} my-2">
    {{message.text}}
  </div>
</ng-container>

<div class="header-container"  [class.header-container--banner]="appHomepageBannerText && isNativeAppVisible && !isActivePrompt" [class.header-container--prompt]="!isNativeAppVisible && isActivePrompt" >
  <app-install-prompt *ngIf="showInstallPrompt" (statusPrompt)="handlePrompt($event)"></app-install-prompt>
  <app-header-banner *ngIf="appHomepageBannerText && isNativeAppVisible" [text]="appHomepageBannerText"></app-header-banner>
  <header class="header" [class.header--short]="isShort">
    <a routerLink="/" class="logo header__logo" title="Go to homepage">
      <img src="/assets/svg/logo-white.svg" alt="UKCC" class="logo__img">
    </a>

    <nav class="header__nav">
      <ul class="main-nav">

        <li class="main-nav__item">
          <a routerLink="/" class="main-nav__link" title="Home">Home</a>
        </li>
        <li class="main-nav__item">
          <a routerLink="/competitions" class="main-nav__link" title="All Competitions">All Competitions</a>
        </li>
        <li class="main-nav__item">
          <a routerLink="/fishing" class="main-nav__link" title="Fishing">Fishing</a>
        </li>
        <li class="main-nav__item">
          <a routerLink="/vehicle" class="main-nav__link" title="Vehicles">Vehicles</a>
        </li>
        <li class="main-nav__item">
          <a routerLink="/tech-and-cash" class="main-nav__link" title="Tackle and Tec">Tech & Cash</a>
        </li>
        <li class="main-nav__item">
          <a routerLink="/instant-wins" class="main-nav__link" title="Instant Wins">Instant Wins</a>
        </li>
        <li class="main-nav__item">
          <a routerLink="/draw-results" class="main-nav__link" title="Draw Results">Draw Results</a>
        </li>
        <li class="main-nav__item">
          <a routerLink="/winners" class="main-nav__link" title="Winners">Winners</a>
        </li>
        <!-- Hide in on the middle screen -->
        <li class="main-nav__item">
          <a routerLink="/faq" class="main-nav__link" title="">FAQ</a>
        </li>
      </ul>

      <button *ngIf="!isMobileMenuVisible" class="header__button header__button_sm nav-button" (click)="handleMobileMenuOpen()">
        <svg-icon name="hamburger-new" class="nav-button__icon nav-button__icon_hamburger"></svg-icon>
      </button>

      <button *ngIf="isMobileMenuVisible" class="header__button header__button_sm nav-button" (click)="handleMobileMenuClose()">
        <svg-icon name="close" class="nav-button__icon nav-button__icon_hamburger"></svg-icon>
      </button>

      <a routerLink="/basket" class="header__button nav-button">
        <span class="nav-button__label" *ngIf="(cart$ | async) as cart; else noCart">{{ cart.cached_item_count }}</span>
        <ng-template #noCart>
          <span class="nav-button__label">
            <span class="nav-button__loading">
              <svg-icon name="circle-notch" [spin]="true"></svg-icon>
            </span>
          </span>
        </ng-template>
        <!-- <svg-icon name="shopping-cart-7-d" class="nav-button__icon nav-button__icon_shopping-cart"></svg-icon> -->
        <svg-icon name="cart-new" class="nav-button__icon nav-button__icon_shopping-cart"></svg-icon>
      </a>
      <a routerLink="/my-account" class="header__button nav-button">
        <svg-icon name="profile-new" class="nav-button__icon"></svg-icon>
      </a>
    </nav>
  </header>


</div>

<ng-template #templateMobileMenu>

  <!-- Mobile menu -->
  <div class="mobile-menu" (click)="handleMobileMenuClose()" [class.mobile-menu_shown]="isMobileMenuVisible"  [class.mobile-menu--banner]="appHomepageBannerText && isNativeAppVisible && !isActivePrompt" [class.mobile-menu--prompt]="!isNativeAppVisible && isActivePrompt">
    <aside class="mobile-menu__panel" (click)="stopPropagation($event)">
      <nav class="mobile-menu__nav">
        <ul class="mobile-menu__nav-list">
          <li class="mobile-menu__item">
            <a class="mobile-menu__link" title="" (click)="navigateForMobileMenuItem()" routerLink="/">Home</a>
          </li>
          <li class="mobile-menu__item">
            <a class="mobile-menu__link" title="" (click)="navigateForMobileMenuItem()" routerLink="/competitions">All Competitions</a>
          </li>
          <li class="mobile-menu__item">
            <a class="mobile-menu__link" title="" (click)="navigateForMobileMenuItem()" routerLink="/fishing">Fishing</a>
          </li>
          <li class="mobile-menu__item">
            <a class="mobile-menu__link" title="" (click)="navigateForMobileMenuItem()" routerLink="/vehicle">Vehicles</a>
          </li>
          <li class="mobile-menu__item">
            <a class="mobile-menu__link" title="" (click)="navigateForMobileMenuItem()" routerLink="/tech-and-cash">Tech & Cash</a>
          </li>
          <li class="mobile-menu__item">
            <a title="" class="mobile-menu__link" (click)="navigateForMobileMenuItem()" routerLink="/instant-wins">Instant Wins</a>
          </li>
          <li class="mobile-menu__item" *ngIf="!isNativeAppVisible && (isIos || isAndroid)">
            <a *ngIf="isAndroid" target="_blank" href="https://play.google.com/store/apps/details?id=uk.co.ukcarpcompetitions.android&hl=en_US" class="mobile-menu__link" title="">Download Our App</a>
            <a *ngIf="isIos" target="_blank" href="https://apps.apple.com/gb/app/uk-carp-competitions/id1637378310" class="mobile-menu__link" title="">Download Our App</a>
          </li>
          <li class="mobile-menu__item">
            <a title="" routerLink="/draw-results" (click)="navigateForMobileMenuItem()" class="mobile-menu__link">Draw Results</a>
          </li>
          <li class="mobile-menu__item">
            <a class="mobile-menu__link" title=""  routerLink="/winners" (click)="navigateForMobileMenuItem()" >Winners</a>
          </li>

          <li class="mobile-menu__item">
            <a class="mobile-menu__link" title="" (click)="navigateForMobileMenuItem()" routerLink="/faq">FAQ</a>
          </li>
        </ul>
      </nav>
      <div class="mobile-menu__bottom-wrapper">

        <a class="mobile-menu__link" href="mailto:info@ukcc.co.uk">info&#64;ukcc.co.uk</a>

        <div class="mobile-menu__footer">
          <span>© UK Carp Competitions Ltd - </span>
          <span>Registered Company No: 13211311</span>
          <span>
            You must be 18 or over to enter
          </span>
        </div>

        <div class="mobile-menu__socials">
          <a href="https://www.facebook.com/ukcc.co.uk" target="_blank" rel="nofollow" class="mobile-menu__social-link" title="" (click)="handleMobileMenuClose()">
            <svg-icon name="facebook-new" class="mobile-menu__social-icon"></svg-icon>
          </a>
          <a href="https://www.instagram.com/ukcc.co.uk/" target="_blank" rel="nofollow" class="mobile-menu__social-link" title="" (click)="handleMobileMenuClose()">
            <svg-icon name="instagram-new" class="mobile-menu__social-icon"></svg-icon>
          </a>
          <!-- <a href="/"
             target="_blank"
             rel="nofollow"
             class="mobile-menu__social-link"
             title=""
             (click)="handleMobileMenuClose()">
            <svg-icon name="youtube-new" class="mobile-menu__social-icon"></svg-icon>
          </a> -->
        </div>
      </div>
    </aside>
  </div>


</ng-template>
<div class="native-nav" *ngIf="isNativeAppVisible">
  <a class="native-nav__button" *ngIf="isIos" (click)="goBack($event)">
    <img src="/assets/images/native/back.svg" alt="Home" width="20" height="20" />
    <div class="native-nav__label">Back</div>
  </a>
  <a routerLink="/" class="native-nav__button">
    <div class="native-nav__icon">
      <img src="/assets/images/native/home.svg" alt="Home" width="20" height="20" />
    </div>
    <div class="native-nav__label">Home</div>
  </a>
  <a routerLink="/competitions" *ngIf="!isIos" class="native-nav__button">
    <div class="native-nav__icon">
      <img src="/assets/images/native/ticket.svg" alt="Home" width="20" height="20" />
    </div>
    <div class="native-nav__label">Comps</div>
  </a>
  <a routerLink="/competition-category/mobile-app" class="native-nav__button">
    <div class="native-nav__icon">
      <img src="/assets/images/native/fire.svg" alt="Home" width="20" height="20" />
    </div>
    <div class="native-nav__label">App-only</div>
  </a>
  <a routerLink="/my-account" class="native-nav__button">
    <div class="native-nav__icon">
      <img src="/assets/images/native/account.svg" alt="Account" width="20" height="20" />
    </div>
    <div class="native-nav__label">Account</div>
  </a>
</div>
