import { Component } from '@angular/core';
import { InitService } from '@core/services/init/init.service';
import { projectData } from '@project/project-data';
import {DomainMigratorService} from "@project/core/services/domain-migrator/domain-migrator.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  constructor(
    public initService: InitService,
    private domainMigrationService: DomainMigratorService
  ) {
    this.initService.init({ projectData });
    this.domainMigrationService.init();
  }
}
